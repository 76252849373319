import { Product, Service, ServiceClosing, ServiceSchedule, SchoolService, Transaction } from "../models/family";
import { endOfDay, format, isAfter, isWithinInterval, parse, startOfDay, sub } from 'date-fns';
import _ from "lodash";
import { OrderInput, CheckoutItem } from "../models/order";

export const DAY_MAP = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
}

export const DEFAULT_SERVICE_CLOSING = { 'day': 0, 'hour': 9, 'minute': 0 };

export const BX_LEAD_TIME_WORKING_DAYS = 2;

export const PUBLIC_HOLIDAY_MAP = { "2019": { "4": [19, 22, 25] }, "6": [3], "10": [28], "12": [25, 26] };

class OrderUtils {
  ezlunchHorizonDays: number = 4;
  disabledSchoolsForPoli: string[] = ['Middleton Grange School'];

  setEzlunchHorizonDays(horizon: number) {
    this.ezlunchHorizonDays = horizon;
  };

  setDisabledSchoolsForPoli(schools: string[]) {
    this.disabledSchoolsForPoli = schools;
  }
  getOrderGroupedByDeliveryDate(items) {
    if (!items) {
      return {};
    }

    const sortedcheckoutOrderItems = Object.values(items).sort((a, b) => {
      const isAUndated = this.isItemUndated(a.detail.closing);
      const isBUndated = this.isItemUndated(b.detail.closing);

      if (isAUndated && !isBUndated) {
        return -1;
      } else if (!isAUndated && isBUndated) {
        return 1;
      } else if (a.detail.service_id === 'fees_and_donations' && b.detail.service_id !== 'fees_and_donations') {
        return -1;
      } else if (a.detail.service_id !== 'fees_and_donations' && b.detail.service_id === 'fees_and_donations') {
        return 1;
      } else {
        // Compare delivery dates
        const dateA = new Date(a.detail.delivery_date);
        const dateB = new Date(b.detail.delivery_date);
        return dateA - dateB;
      }
    });

    const groupedStudents = {};
    sortedcheckoutOrderItems.forEach((item) => {
      const deliveryDate = this.isItemUndated(item.detail.closing) ? '' : item.detail.delivery_date;

      if (!groupedStudents.hasOwnProperty(deliveryDate)) {
        groupedStudents[deliveryDate] = {
          students: []
        };
      }

      const studentsWithTotalPrice = item.detail.students.map((student) => ({
        ...student,
        totalPrice: item.total_price_in_cents,
        orderId: item.order_id,
        serviceFee: item.detail.per_lunchbox_fee,
        schoolId: item.detail.school_id
      }));

      groupedStudents[deliveryDate].students.push(...studentsWithTotalPrice);
    });

    return groupedStudents;
  }

  getOrderSchoolIds(items) {
    if (!items) {
      return {};
    }

    return items.map((item) => item.detail.school_id);
  }

  areAllSchoolIdsMiddleton(schoolIds) {
    if (!Array.isArray(schoolIds)) {
      return false;
    }

    return schoolIds.every((schoolId) => schoolId === 'Middleton Grange School');
  }

  isPoliDisabled(schoolIds) {
    if (!Array.isArray(schoolIds)) {
      return false;
    }

    if (!Array.isArray(this.disabledSchoolsForPoli)) {
      return false;
    }

    const result = schoolIds.every((schoolId) => this.disabledSchoolsForPoli.includes(schoolId));
    return result;
  }

  getClosingInfo(closing: ServiceClosing = { day: 0, hour: 0, minute: 0 }) {
    const closingDate = new Date();
    closingDate.setHours(closing.hour);
    closingDate.setMinutes(closing.minute);
    let closingInfo = '';
    if (closing.hour > 0 || closing.minute > 0) {
      closingInfo += `at ${format(closingDate, `h:mma`)}`;
    }
    if (closing.day > 0) {
      closingInfo += `, ${closing.day} day${closing.day > 1 ? 's' : ''} prior`;
    }
    return closingInfo ? `${closingInfo.toLowerCase()}` : '';
  }

  getAvailabilityInfo(service: Service) {
    const availability = Object.values(service.schedule.delivery_days);
    if (_.every(availability)) {
      return 'Available all week';
    }
    if (_.every(availability.slice(0, 6))) {
      return 'Available on weekdays and Saturday';
    }
    if (_.every(availability.slice(0, 5))) {
      return 'Available on weekdays';
    }

    const availabilityCount = _.countBy(availability);
    const deliveryDayKeys = Object.keys(service.schedule.delivery_days);
    const availableDeliveryDays = deliveryDayKeys
      .filter((day) => service.schedule.delivery_days[day]);

    const hasConsecutiveDaysWithNoGaps = availableDeliveryDays
      .every((day, index) => {
        if (index === 0) {
          return true;
        }
        const previousDay = availableDeliveryDays[index - 1];
        // Check if consecutive by checking if the difference between the current day and the previous day is 1
        return parseInt(day) - parseInt(previousDay) === 1;
      });
    const availableDeliveryDayValues = availableDeliveryDays
      .map((day) => DAY_MAP[day]);
    if (hasConsecutiveDaysWithNoGaps && availableDeliveryDayValues.length > 2) {
      // Example: Available Monday to Friday
      const firstAvailableDay = availableDeliveryDayValues[0];
      const lastAvailableDay = availableDeliveryDayValues[availableDeliveryDayValues.length - 1];
      return `Available on ${firstAvailableDay} to ${lastAvailableDay}`;
    }
    if (availabilityCount.true >= 3) {
      const availableDays = availableDeliveryDayValues.map((day) => `${day}s`).join(', ')
      return `Available on ${availableDays} only`;
    }
    if (availabilityCount.true === 2) {
      const availableDays = availableDeliveryDayValues.map((day) => `${day}s`).join(' and ')
      return `Available on ${availableDays} only`;
    }
    if (availabilityCount.true === 1) {
      const availableDays = availableDeliveryDayValues.map((day) => `${day}s`).join('')
      return `Available on ${availableDays}`;
    }

    // Example: Available everyday except Wednesday
    const unavailableDeliveryDays = deliveryDayKeys
      .filter((day) => !service.schedule.delivery_days[day])
      .map((day) => DAY_MAP[day]);
    const unavailableDays = unavailableDeliveryDays.map((day) => `${day}s`).join(', ')
    return `Available everyday except ${unavailableDays}`;

  }

  getNextAvailableDate(schedule: ServiceSchedule, closing: ServiceClosing = { day: 0, hour: 0, minute: 0 }) {
    const { windows, delivery_days, events } = schedule;

    const currentDate = new Date();
    const availableWindows = windows.filter((window) => {
      const minDate = startOfDay(new Date(window.min_date));
      const aWeekBefore = sub(minDate, { days: this.ezlunchHorizonDays });
      const maxDate = endOfDay(new Date(window.max_date));
      return (isWithinInterval(currentDate, { start: minDate, end: maxDate }) || isAfter(currentDate, aWeekBefore))
    });
    for (const window of availableWindows) {
      const minDate = endOfDay(new Date(window.min_date));
      const maxDate = endOfDay(new Date(window.max_date));

      let nextDate = new Date(minDate);
      if (nextDate < new Date() && nextDate < maxDate) {
        // No point to check from an earlier date than today
        nextDate = new Date();
      }

      while (nextDate <= maxDate) {
        const nextDateString = this.getNextDateIfAvailable({ nextDate, closing, delivery_days, events })
        if (nextDateString) {
          return nextDateString;
        }

        nextDate.setDate(nextDate.getDate() + 1);
      }
    }

    return null;
  }

  /** Gets up to 100 available dates for a service */
  getAvailableDates(service?: Service) {
    const schedule = service?.schedule;
    const closing = service?.closing || { day: 0, hour: 0, minute: 0 };
    if (!schedule) return [];
    const isEzlunchService = service?.brand_name === 'ezlunch';
    const { windows, delivery_days, events } = schedule;
    const availableDates = [];

    const currentDate = new Date();
    const availableWindows = windows.filter((window) => {
      const minDate = startOfDay(new Date(window.min_date));
      const aWeekBefore = sub(minDate, { days: this.ezlunchHorizonDays });
      const maxDate = endOfDay(new Date(window.max_date));
      // Ezlunch service is available 7 days before the start of window
      return (isWithinInterval(currentDate, { start: minDate, end: maxDate })
        || (isEzlunchService && isWithinInterval(currentDate, { start: aWeekBefore, end: maxDate })))
    });
    for (const window of availableWindows) {
      const minDate = endOfDay(new Date(window.min_date));
      const maxDate = endOfDay(new Date(window.max_date));

      let nextDate = new Date(minDate);
      if (nextDate < new Date() && nextDate < maxDate) {
        // No point to check from an earlier date than today
        nextDate = new Date();
      }
      while (
        nextDate <= maxDate
        && availableDates.length < 100 // Prevents lag from checking too many dates
      ) {
        const nextDateString = this.getNextDateIfAvailable({ nextDate, closing, delivery_days, events })
        if (nextDateString) {
          availableDates.push(nextDateString);
        }

        nextDate.setDate(nextDate.getDate() + 1);
      }
    }

    return availableDates;
  }

  /**
   * Helper function to determine if a date is available for ordering
   * used by getNextAvailableDate and getAvailableDates
   * @param param0 
   * @returns next date string if available, false if not
   */
  getNextDateIfAvailable(
    { nextDate, closing, delivery_days, events }:
      { nextDate: Date, closing: ServiceClosing, delivery_days: ServiceSchedule['delivery_days'], events: ServiceSchedule['events'] }
  ) {
    const currentDate = new Date();
    const day = nextDate.getDay();
    const nextDayOfWeek = day === 0 ? 7 : day;
    const nextDateString = format(nextDate, 'yyyy-MM-dd');

    const closingDate = new Date(nextDate);
    closingDate.setDate(closingDate.getDate() - closing.day);
    closingDate.setHours(closing.hour);
    closingDate.setMinutes(closing.minute);

    if (
      nextDate >= currentDate // Checks if nextDate is in the future
      && delivery_days[nextDayOfWeek] // Checks if nextDate is on an available delivery day
      && events[nextDateString]?.available !== false // Checks if nextDate is not on an unavailable event
      && currentDate < closingDate // Checks if todays date is before the closing date relative to nextDate
    ) {
      return nextDateString;
    }
    return false;
  }

  /**
   * Gets the earliest next available ezlunch service
   * @param schoolService the school service with all services
   * @returns The earliest next available ezlunch service
   */
  getNextAvailableEzlunchService(schoolService: SchoolService) {
    const ezlunchServices = _.groupBy(schoolService.services, 'brand_name')['ezlunch'];
    if (!ezlunchServices) {
      return null;
    }
    type NextAvailableService = {
      nextAvailableDate: string;
    } & Service;
    const firstAvailableServices = ezlunchServices.reduce<NextAvailableService[]>((all, service) => {

      const nextAvailable = this.getNextAvailableDate(service.schedule, service.closing);
      if (nextAvailable) {
        return [
          ...all,
          {
            ...service,
            nextAvailableDate: nextAvailable
          }
        ]
      }
      return all;
    }, []);

    const earliestService = _.orderBy(firstAvailableServices, (s) => new Date(s.nextAvailableDate))[0];
    return earliestService
  }

  /** Gets the most recent closing date (that just happened) for a service */
  getPreviousClosingDate(service?: Service) {
    const windows = service?.schedule?.windows;
    if (!windows) return null;
    let lastWindowMaxDate = null;
    const currentDate = new Date();
    for (const window of windows) {
      const maxDate = new Date(window.max_date);
      if ((!lastWindowMaxDate || maxDate > lastWindowMaxDate) && maxDate < currentDate) {
        lastWindowMaxDate = maxDate;
      }
    }
    return lastWindowMaxDate;
  }

  /** Gets the next available opening window */
  getNextOpeningDate(service?: Service) {
    const windows = service?.schedule?.windows;
    if (!windows) return null;
    let nextWindowMinDate = null;
    const isEzlunchService = service?.brand_name === 'ezlunch';
    const currentDate = new Date();
    for (const window of windows) {
      // Ezlunch services open 7 days before the start of window
      const min = startOfDay(new Date(window.min_date));
      const minDate = isEzlunchService ? sub(min, { days: this.ezlunchHorizonDays }) : min;
      if (minDate > currentDate && (!nextWindowMinDate || minDate < nextWindowMinDate)) {
        nextWindowMinDate = minDate;
      }
    }
    return nextWindowMinDate;
  }

  isOpenedForOrders(service?: Service) {
    const windows = service?.schedule?.windows;
    if (!windows) return false;
    const isEzlunchService = service?.brand_name === 'ezlunch';
    const currentDate = new Date();
    for (const window of windows) {
      // Ezlunch services open 7 days before the start of window
      const min = startOfDay(new Date(window.min_date));
      const minDate = isEzlunchService ? sub(min, { days: this.ezlunchHorizonDays }) : min;
      const maxDate = endOfDay(new Date(window.max_date));
      if (minDate < currentDate && currentDate < maxDate) {
        return true;
      }
    }
    return false;
  }

  isServiceUndated(service?: Service) {
    if (!service) return true;
    // Checks if service is undated by checking if closing is the at 11:59pm and delivers everyday.
    return service.brand_name !== 'ezlunch' &&
      (service.closing.minute === 59 && service.closing.hour === 23 && service.closing.day === 0
        && _.every(service.schedule.delivery_days));
  }

  isItemUndated(closing: any) {
    // Checks if item is undated on checkout order
    return closing.minute === 59 && closing.hour === 23 && closing.day === 0
  }

  // Gets input for addOrder mutation
  getAddOfferInput({
    product
  }: { product: Product; }) {
    return {
      closing: product.service?.closing,
      creator: 'checkout.shtml',
      delivery_date: format(new Date(), 'yyyy-MM-dd'),
      order_fee: 0,
      per_lunchbox_fee: 0,
      rtype: 'order_detail',
      school_id: product.schoolId,
      service_id: product.service?.id,
      students: [
        {
          member_id: null,
          member_type: "family",
          student_id: "FAMILY",
          room: null,
          address: null,
          firstName: "familyFirstName",
          products: [
            {
              gst: product.gst,
              meta: "product",
              permanent_id: product.permanent_id,
              product: product.product,
              productId: product.productId,
              quantity: 1,
              quoted_unit_price_in_cents: parseInt(product.price_in_cents),
              quoted_product_option_price_in_cents: product.option_price_in_cents ?? 0,
              included_option_count: product.included_option_count,
              options: product.options,
              choice: product.choice,
              ...(product.questions?.length ? { questions: product.questions } : {}),
            }
          ]
        }
      ],
    } as OrderInput;
  }

  walkWorkingDay(max: number, date: Date, dateMaybe: Date | null, holiday: any) {
    if (dateMaybe && date) {
      throw new Error('Date is not possible');
    }

    const weekend = [6, 0]; // sat sun
    let i = 0;

    const newDate = new Date(date.valueOf());

    while (i < max && (!dateMaybe || newDate < dateMaybe)) {
      if (weekend.indexOf(newDate.getDay()) === -1) {
        const yearHoliday = holiday[newDate.getFullYear().toString()] || {};
        const monthHoliday = yearHoliday[(newDate.getMonth() + 1).toString()] || [];
        const isHoliday = monthHoliday.indexOf(newDate.getDate()) > -1;

        if (!isHoliday) {
          i++;
        }
      }
      newDate.setDate(newDate.getDate() + 1);
    }

    return [i, newDate];
  }

  getLateBxOrders() {
    const dateBxExpected = this.walkWorkingDay(BX_LEAD_TIME_WORKING_DAYS, new Date(), null, PUBLIC_HOLIDAY_MAP)[1];
    const formattedDateBxExpected = new Date(dateBxExpected);

    formattedDateBxExpected.setHours(8);
    formattedDateBxExpected.setMinutes(0);
    formattedDateBxExpected.setSeconds(0);

    return formattedDateBxExpected;
  }

  getLateForBxOrders(items: CheckoutItem[]) {
    let invalidOrders: any[] = []
    if (items) {
      items.forEach((item: any) => {
        let closing = item.detail.closing ? item.detail.closing : DEFAULT_SERVICE_CLOSING;
        let noClosingValue = (closing.hour === 23 && closing.minute === 59);

        if ((!noClosingValue) && this.isStale(item.detail.delivery_date, closing, true)) {
          invalidOrders.push(item)
        }
      });
    }

    return invalidOrders
  }

  isWeekend(date: Date) {
    const day = date.getDay();
    return day === 0 || day === 6; // 0 is Sunday, 6 is Saturday
  }

  addWorkingDays(date: Date, days: number) {
    let result = new Date(date);
    while (days > 0) {
      result.setDate(result.getDate() + 1);
      if (!this.isWeekend(result)) {
        days -= 1;
      }
    }
    return result;
  }

  getClosingForOrderDate(date: Date | any, closing: ServiceClosing) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - closing.day, closing.hour, closing.minute);
  }

  isStale(deliveryDate: Date | any, closing: ServiceClosing, isBx?: boolean) {
    if (!(deliveryDate instanceof Date)) {
      deliveryDate = new Date(deliveryDate);
    }
    const now = new Date();

    const dateClosing = this.getClosingForOrderDate(deliveryDate, closing)

    const twoWorkingDaysFromNow = this.addWorkingDays(now, 2);

    return dateClosing < (isBx ? twoWorkingDaysFromNow : now);
  }

  isStaleHelpDesk(deliveryDate: Date | any, closing: ServiceClosing): boolean {
    const helpdeskClosing = { hour: 1, minute: 30 };
    const deliveryDateInstance = deliveryDate instanceof Date ? deliveryDate : new Date(deliveryDate);

    // Calculate late closing time
    const lateClosing = this.getClosingForOrderDate(deliveryDateInstance, {
      day: closing.day,
      hour: closing.hour + helpdeskClosing.hour,
      minute: closing.minute + helpdeskClosing.minute
    });

    // Calculate the latest closing time
    const latestClosing = this.getClosingForOrderDate(deliveryDateInstance, {
      day: closing.day,
      hour: 23,
      minute: 59
    });

    // Adjust late closing if it exceeds the latest closing
    const finalLateClosing = isAfter(lateClosing, latestClosing) ? latestClosing : lateClosing;

    const now = new Date();
    return isAfter(now, finalLateClosing);
  }

  isOrderStale(order: CheckoutItem, service: Service) {
    const closing = order.detail.closing ? order.detail.closing : DEFAULT_SERVICE_CLOSING;

    if (!this.isItemUndated(order.detail.closing)) {
      const isStale = this.isStale(order.detail.delivery_date, closing)
      if (isStale) {
        return isStale;
      }
      // Check if delivery date is one of the available dates for service
      const availableDates = this.getAvailableDates(service);
      const isNotAvailable = !_.some(availableDates, (date) => {
        return date === order.detail.delivery_date;
      })
      return isNotAvailable;
    } else {
      return this.getAvailableDates(service).length === 0;
    }
  }

  isAvailableForDelivery(availableDays: number[], dates: Date[]) {
    return _.some(availableDays, (day) => {
      return _.some(dates, (date) => {
        return parseInt(format(new Date(date), 'i'), 10) === day;
      });
    })
  }

  isAvailableForDeliveryForAllDates(availableDays: number[], dates: Date[]) {
    return _.every(dates, (date) => {
      return _.some(availableDays, (day) => {
        return parseInt(format(new Date(date), 'i'), 10) === day;
      });
    })
  }

  // Purchases containing school payments (eg etap payments) cannot be cancelled
  // Only purchases are cancellable
  isTransactionCancellable(transaction: Transaction) {
    const currentDate = new Date();
    const isCancelled = transaction.cancelled || transaction.is_cancelled;
    const isPending = Object.values(transaction.status).every(student => Object.values(student).every(item => item === "pending"));
    const isPurchaseTransaction = transaction.transaction_subtype === "purchase";
    const orderDate = transaction.delivery_date ? transaction.delivery_date : transaction.order_date;
    const [year, month, day] = orderDate.split("-").map(Number);
    const orderDateTime = new Date(year, month - 1, day, 23, 59, 0);
    const isDeliveryDateValid = currentDate <= orderDateTime

    if (!isCancelled && isPending && isPurchaseTransaction && isDeliveryDateValid) {
      return true;
    } else {
      return false;
    }
  }

  getTransactionsGroupedByDeliveryDate(transactions?: Transaction[]) {
    if (!transactions) {
      return [];
    }

    const groupedTransactions = _.groupBy(transactions, (t) => format(new Date(t.transaction_datetime), 'EEE dd MMM yyyy'));

    // Sorting keys and creating a sorted array of objects
    const sortedDates = Object.keys(groupedTransactions).sort((a, b) => {
      const dateA = +parse(a, 'EEE dd MMM yyyy', new Date());
      const dateB = +parse(b, 'EEE dd MMM yyyy', new Date());
      return dateB - dateA;
    });
    const sortedTransactions = sortedDates.map(date => ({
      date,
      transactions: groupedTransactions[date].sort((a, b) => {
        const dateA = +new Date(a.transaction_datetime);
        const dateB = +new Date(b.transaction_datetime);
        return dateB - dateA;
      })
    }));

    return sortedTransactions;
  }

}
const orderUtils = new OrderUtils();
export default orderUtils;