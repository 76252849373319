import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";
// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyBIXN7SQ7bflTbH_UOndYOvTxLnuuQS-Nk",
  authDomain: "mykindo-8c193.firebaseapp.com",
  projectId: "mykindo-8c193",
  storageBucket: "mykindo-8c193.appspot.com",
  messagingSenderId: "512632399026",
  appId: "1:512632399026:web:ffad0c872cd4654363d5ab",
  measurementId: "G-4E7JDLCPDS"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const remoteConfig = getRemoteConfig(app);