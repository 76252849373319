import React from 'react'
import orderUtils from 'networking/util/order';
import { remoteConfig } from 'util/firebase';
import { fetchAndActivate, getAll, getBoolean, getNumber, getString } from 'firebase/remote-config';

remoteConfig.settings.minimumFetchIntervalMillis = 5000; // 1 hour

remoteConfig.defaultConfig = {
  // These are just the defaults, the actual values are fetched from the firebase backend
  ezlunch_horizon_days: 7,
  disabled_schools_for_poli: 'Middleton Grange School',
  online_eftpos_topup_amount_limit_in_cents: 25000,
  in_maintenance_mode_web: false,
  payable_rollover_year: 2024,
}

type RemoteConfigValues = {
  ezlunch_horizon_days: number;
  disabled_schools_for_poli: string;
  online_eftpos_topup_amount_limit_in_cents: number;
  in_maintenance_mode_web: boolean;
  payable_rollover_year: number;
}

/**
 * Hook to initialize remote config 
 * Want to add a new remote config key/value?
 * STEP 1: Add the new value to the default values in the remote config initialization
 * STEP 2: Add/Update the value in firebase console in remote config section
 */
const useRemoteConfig = () => {
  const [initialized, setInitialized] = React.useState(false);
  const [inMaintenanceMode, setInMaintenanceMode] = React.useState(false);
  const getRemoteConfigValue = (key: keyof RemoteConfigValues) => {
    const values = getAll(remoteConfig);
    return values[key];
  }
  React.useEffect(() => {
    fetchAndActivate(remoteConfig).then((refetched) => {
      const ezlunchHorizonDays = getNumber(remoteConfig, 'ezlunch_horizon_days');
      if (ezlunchHorizonDays) {
        orderUtils.setEzlunchHorizonDays(ezlunchHorizonDays);
      }

      const disabledSchoolsForPoli = getString(remoteConfig, 'disabled_schools_for_poli');
      if (disabledSchoolsForPoli) {
        orderUtils.setDisabledSchoolsForPoli(disabledSchoolsForPoli.split(',').map(s => s.trim()));
      }

      const inMaintenanceMode = getBoolean(remoteConfig, 'in_maintenance_mode_web');
      setInMaintenanceMode(inMaintenanceMode);
      setInitialized(true);
    }).catch((err) => {
      console.log('Was not able to fetch remote config values', err);
    })
  }, []);

  return {
    initialized,
    inMaintenanceMode,
    getRemoteConfigValue,
  };
}

export default useRemoteConfig